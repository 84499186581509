<template>
  <div class="knives__products">
    <div class="container">
      <div class="knives__products-buttons">
        <button
          class="knives__products-btn"
          :class="{ active: isShowKnifes }"
          @click="showKnifes"
        >
          {{ $t("knives.knivesItems") }}
        </button>
        <button
          class="knives__products-btn"
          :class="{ active: isShowRecipe }"
          @click="showRecipe"
        >
          {{ $t("knives.recipeItems") }}
        </button>
      </div>
      <p class="knives__products-action">
        {{ $t("knives.productClick") }}
      </p>
      <div v-if="isShowKnifes" class="knives__products-inner">
        <div
          v-for="(item, index) in knifes.slice(0, 2)"
          :key="index"
          class="knives__product"
          @click="showKnifesModal(item)"
        >
          <div class="knives__product-left">
            <div class="knives__product-title">{{ $t(item.title) }}</div>
            <div class="knives__product-discount">
              <span class="knives__product-discount--number">{{
                item.discount
              }}</span>
            </div>
          </div>
          <div class="knives__product-right">
            <img class="knives__product-img" :src="item.image" alt="" />
            <div class="knives__product-price">
              <p class="knives__product-oldprice">
                {{ item.oldPrice }}
                <img src="@/assets/img/knives/tenge.svg" alt="" />
              </p>
              <p class="knives__product-newprice">
                {{ item.newPrice }}
                <img src="@/assets/img/knives/tenge.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
        <div v-if="isShowKnifes" class="knives__products-stickers">
          <img
            v-if="$i18n.locale === 'ru'"
            class="knives__products-stickers--img"
            src="@/assets/img/knives/stickers.svg"
            alt=""
          />
          <img
            v-else
            class="knives__products-stickers--img"
            src="@/assets/img/knives/stickers-kz.svg"
            alt=""
          />
          <div class="knives__products-stickers--text">
            <p>{{ $t("knives.stickersText") }}</p>
          </div>
        </div>
      </div>
      <div v-if="isShowKnifes" class="knives__products-inner">
        <div
          v-for="(item, index) in knifes.slice(2, knifes.length)"
          :key="index"
          class="knives__product"
          @click="showKnifesModal(item)"
        >
          <div class="knives__product-left">
            <div class="knives__product-title">{{ $t(item.title) }}</div>
            <div class="knives__product-discount">
              <span class="knives__product-discount--number">{{
                item.discount
              }}</span>
            </div>
          </div>
          <div class="knives__product-right">
            <img class="knives__product-img" :src="item.image" alt="" />
            <div class="knives__product-price">
              <p class="knives__product-oldprice">
                {{ item.oldPrice }}
                <img src="@/assets/img/knives/tenge.svg" alt="" />
              </p>
              <p class="knives__product-newprice">
                {{ item.newPrice }}
                <img src="@/assets/img/knives/tenge.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShowRecipe" class="knives__recipe-inner">
        <div
          v-for="(item, index) in recipes"
          :key="index"
          class="knives__recipe"
          @click="showRecipeModal(index)"
        >
          <div class="knives__recipe-img">
            <img :src="item.image" alt="" />
          </div>
          <div class="knives__recipe-content">
            <h6 class="knives__recipe-title">{{ $t(item.title) }}</h6>
            <div class="knives__recipe-info">
              <div class="knives__recipe-time">
                <p>{{ $t("knives.time") }}</p>
                <p>30 минут</p>
              </div>
              <div class="knives__recipe-complexity">
                <p>{{ $t("knives.complexity") }}:</p>
                <div class="knives__recipe-rating">
                  <svg
                    v-for="(item, index) in 5"
                    :key="index"
                    :class="{ active: item <= 2 }"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8133 13.6H4.10216C3.54987 13.6 3.10216 13.1523 3.10216 12.6V11.8787C3.10216 11.4641 2.83936 11.0976 2.4672 10.9147C-1.68964 8.87167 0.0659284 4.48595 2.90085 3.71744C3.38329 3.58666 3.80039 3.22326 3.99028 2.76088C5.26959 -0.354105 10.9432 -1.5274 13.0302 2.9736C13.2018 3.34356 13.5568 3.60506 13.9596 3.66857C18.4356 4.37435 17.2849 9.85171 14.8729 10.9905C14.3425 11.2409 13.8133 11.6944 13.8133 12.2808V12.6C13.8133 13.1523 13.3655 13.6 12.8133 13.6Z"
                      fill="#F0C9D9"
                    />
                    <path
                      d="M12.8133 14.7333H4.10216C3.54987 14.7333 3.10216 15.181 3.10216 15.7333V16C3.10216 16.5523 3.54987 17 4.10216 17H12.8133C13.3655 17 13.8133 16.5523 13.8133 16V15.7333C13.8133 15.181 13.3655 14.7333 12.8133 14.7333Z"
                      fill="#F0C9D9"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-knives-modal
      v-if="isShowKnivesModal"
      @close="isShowKnivesModal = false"
      :id="selectKnifeId"
    />
    <app-recipe-modal
      v-if="isShowRecipeModal"
      @close="isShowRecipeModal = false"
      :index="selectRecipeIndex"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KnivesProducts",
  components: {
    AppKnivesModal: () => import("@/components/modal/AppKnivesModal.vue"),
    AppRecipeModal: () => import("@/components/modal/AppRecipeModal.vue"),
  },
  computed: {
    ...mapState("knivesModule", ["knifes", "recipes"]),
  },
  data() {
    return {
      isShowKnifes: true,
      isShowRecipe: false,
      selectKnifeId: null,
      selectRecipeIndex: null,
      isShowKnivesModal: false,
      isShowRecipeModal: false,
      // recipes: {
      //   {

      //   }
      // },
    };
  },
  watch: {
    // isShowKnivesModal: {
    //   handler(newVal) {
    //     if (newVal) {
    //       document.querySelector("html").style.overflow = "hidden";
    //     } else {
    //       document.querySelector("html").style.overflow = "initial";
    //     }
    //   },
    // },
    isShowRecipeModal: {
      handler(newVal) {
        if (newVal) {
          document.querySelector("html").style.overflow = "hidden";
        } else {
          document.querySelector("html").style.overflow = "initial";
        }
      },
    },
  },
  methods: {
    showKnifesModal(item) {
      this.selectKnifeId = item.id;
      console.log(this.selectKnifeId);
      this.isShowKnivesModal = true;
    },
    showRecipeModal(index) {
      this.selectRecipeIndex = index;
      this.isShowRecipeModal = true;
    },
    showKnifes() {
      this.isShowKnifes = true;
      this.isShowRecipe = false;
    },
    showRecipe() {
      this.isShowKnifes = false;
      this.isShowRecipe = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
